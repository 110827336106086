import axios, { CancelToken } from 'axios'

import GraphQL from '@/api/GraphQL'

export default class CommonApi {
  constructor(token) {
    const client = axios.create({
      baseURL: process.env.VUE_APP_PROFILE_API + '/common/',
      timeout: 25000,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
    this.client = client
    this.gql = new GraphQL(client)
    this.cancels = new Map()
  }

  /**
   * Retrieve a list of trusts
   */
  trusts() {
    return this.client.get('/trusts')
  }

  listProvider(type) {
    return query => this.gql.query(type, query)
  }

  singleProvider(type) {
    return query =>
      this.listProvider(type)(query).then(rows => {
        if (rows.Results.length === 0) {
          throw new Error('Not found')
        }
        return rows.Results[0]
      })
  }

  mutationProvider(type, mutation) {
    return data => this.gql.mutate(type, mutation, data)
  }

  fieldsProvider(type) {
    return query => {
      const qOpts = {}

      if (query.cancelToken) {
        let ct = this.cancels.get(query.cancelToken)
        if (ct) {
          ct.cancel('query has been terminated by new query')
          this.cancels.delete(query.cancelTOken)
        }
        ct = CancelToken.source()
        this.cancels.set(query.cancelToken, ct)
        qOpts.cancelToken = ct.token
      }

      return this.client
        .get(`entity/${type}`, qOpts)
        .then(entity => ({
          Entity: entity.data,
          Sections: entity.data.Sections
        }))
        .finally(() => {
          if (query.cancelToken) {
            this.cancels.delete(query.cancelTOken)
          }
        })
    }
  }
}
