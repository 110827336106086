<template>
  <div class="main">
    <!-- Info about download being in progress and a link to 'home' -->
    <div>
      <h1 v-if="error">Det gick inte att ladda ner filen</h1>
      <h1 v-else-if="loading">Hämtar fil</h1>
      <h1 v-else>Filen laddas ner</h1>
      <router-link :to="{ name: 'home' }">Till startsidan</router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'download',
  props: {},
  computed: {},
  data() {
    return {
      client: null,
      error: '',
      loading: true
    }
  },
  methods: {
    download() {
      this.loading = true
      this.error = ''
      const path = `link/${this.$route.params.bucket}/${this.$route.params.path}`
      this.client
        .get(path)
        .then(response => {
          window.location = response.data
          this.loading = false
        })
        .catch(error => {
          this.error = error
        })
    }
  },
  mounted() {
    const session = typeof sessionStorage !== 'undefined' ? JSON.parse(sessionStorage.getItem('session')) : null
    if (!session) {
      this.$router.push({ name: 'login' })
      return
    }

    this.client = axios.create({
      baseURL: process.env.VUE_APP_PROFILE_API + '/files/',
      timeout: 25000,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + session.token
      }
    })

    this.download()
  },
  components: {}
}
</script>
